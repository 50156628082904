<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸算法助阵X-Club XTP开发者大会
              </p>
              <div class="fst-italic mb-2">2020年12月18日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >中泰XTP大会</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <figure class="mb-4">
                  <img
                    class="img-fluid rounded w-100"
                    src="../../assets/img/news/04/image01.jpg"
                    alt="中泰XTP大会"
                  />
                </figure>
                <section class="mb-5">
                  <p class="mb-4">
                    2020年12月18日，由中泰证券主办的“X-Club XTP开发者大会”在上海隆重举行。这场年底盛会，云集了国内最多的优秀私募和金融科技方案提供商，这些“最强大脑”碰撞出了最极客的智慧火花。
                  </p>
                  <img
                    class="img-fluid rounded w-100 mb-4"
                    src="../../assets/img/news/04/image02.jpg"
                    alt="中泰XTP大会"
                  />
                  <p class="mb-4">
                    非凸科技作为中泰XTP系统的首家智能算法服务商有幸参与此次千人盛会，在极速交易的高速公路上，共同为投资者“护航”。
                  </p>
                  <p class="mb-4">
                    中泰证券科技研发部总经理何波表示，程序化交易在美国的交易占比高达八成，国内仍有广阔发展空间。交易规则明显变化，资本市场改革步伐越来越大，规则会让策略更复杂化，也会更智慧化、程序化和人工智能化。
                  </p>
                  <img
                    class="img-fluid rounded w-100 mb-4"
                    src="../../assets/img/news/04/image03.jpg"
                    alt="中泰XTP大会"
                  />
                  <p class="mb-4">
                    未来十年是A股市场算法交易发展的黄金时期，非凸科技将继续致力于与券商机构的合作，共同构建数智交易生态圈，赋能行业可持续发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News01",
};
</script>

<style></style>
